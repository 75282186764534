
.quality-course-footer {
    font-size: 12px;
    color: #898D96;
    background: #D2D4D7;
    font-weight: 400;
    line-height: 22px;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    .wrap-content {
        margin: 0 auto;
    }
}
.footer-info {
    a {
        font-size: 16px;
        color: #333;
        &:hover {
            color: blue;
        }
    }
}

